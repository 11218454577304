<template>
  <div class="page-appraosal">
    <div class="breadcrumb">
      <span>首页</span>
      <img src="@/assets/images/icon-right-grey.png" alt="" />
      <span>鉴定中心</span>
    </div>
    <div class="header">
      <div class="combination-bg">推荐</div>
      <!-- <img src="@/assets/images/combination.png" alt="" /> -->
      <div class="right">
        <p class="title">专业鉴定</p>
        <div class="accordion">
          <div class="accordion-left">
            <p>累计鉴定</p>
            <p>{{ accumulate }}</p>
          </div>
          <div class="accordion-right">
            <p>鉴定游戏</p>
            <div class="marquee-wrap">
              <ul class="marquee-box" id="marquee-box">
                <li
                  class="marquee-list"
                  v-for="(i, index) in appraisalList"
                  :key="index"
                  :id="i == 1 ? 'marquee' : ''"
                >
                  <img :src="i.image" alt="" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="btn" @click="gopage('pay')">去鉴定</div>
        <div class="box">
          <div class="summary">
            <img src="@/assets/images/appraosal-icon1.png" alt="" />
            <text>专业评估表</text>
          </div>
          <div class="summary">
            <img src="@/assets/images/appraosal-icon2.png" alt="" />
            <text>一对一服务</text>
          </div>
          <div class="summary">
            <img src="@/assets/images/appraosal-icon3.png" alt="" />
            <text>极速帮卖</text>
          </div>
        </div>
        <div class="line"></div>
        <div class="footer">
          <div class="footer-left">
            <img src="@/assets/images/appraosal-icon4.png" alt="" />
            <div>
              <p>免费鉴定</p>
              <p>AI自动鉴定估价</p>
            </div>
          </div>
          <div class="footer-right" @click="gopage('free')">
            <text>去鉴定</text>
            <img src="@/assets/images/icon-right-grey.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="swiper">
      <el-carousel
        indicator-position="outside"
        height="184px"
        trigger="click"
        arrow="never"
        ref="carousel"
        @change="carouselChange"
      >
        <el-carousel-item v-for="item in bannerList" :key="item">
          <img :src="item.image" alt="" style="width: 100%" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="best-box-title">
      <div class="left">
        <img src="@/assets/images/vertical-icon.png" alt="" />
        <p>顶级账号</p>
      </div>
    </div>
    <div class="list">
      <div class="item" v-for="item in list" :key="item.id" @click="shopDetails(item)">
        <div class="img">
          <img :src="item.image[0]" alt="" />
          <p class="official" v-if="item.admin_id > 0">官方发布</p>
          <p class="user" v-else>用户发布</p>
        </div>
        <div class="introduce">{{ item.title }}</div>
        <div class="price">
          <p>售价</p>
          <p>￥{{ item.price / 100 }}</p>
        </div>
        <div class="district">
          游戏区服：{{ item.params[0].name }}-{{ item.params[0].param_value }}
        </div>
      </div>
    </div>
    <div style="margin-top: 20px; text-align: center">
      <a-pagination
        v-model:current="page.pageSize"
        :total="total"
        @change="onChange"
        :defaultPageSize="8"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from 'vue'
import { appraisalInfo } from '@/api/identify'
import { getGoods } from '@/api/home'
import { useRouter } from 'vue-router'
import { findBanner } from '@/api/home'
const router = useRouter()
const disx = ref(0)
const carousel = ref(null)
const accumulate = ref(null)
const cost = ref(null)
const list = ref([])
const total = ref(null)
const appraisalList = ref([])
const bannerList = ref([])
const page = reactive({
  pageNum: 8,
  pageSize: 1
})
let params = {
  page: page.pageSize,
  page_size: page.pageNum,
  is_top: 1
}
function getList() {
  getGoods(params).then((res) => {
    if (res.code == 200) {
      list.value = res.data.list
      total.value = res.data.total
    }
  })
}
const getBannerList = () => {
  findBanner(4).then((res) => {
    if (res.code == 200) {
      bannerList.value = res.data
    }
  })
}
getBannerList()
const onChange = (val) => {
  page.pageSize = val
  getList()
}
function runMarquee() {
  // 获取文字 计算后宽度
  if (appraisalList.value.length > 1) {
    const width = document.getElementById('marquee').getBoundingClientRect().width
    const marquee = document.getElementById('marquee-box')
    setInterval(() => {
      disx.value--
      if (-disx.value >= width) {
        disx.value = 10
      }
      marquee.style.left = disx.value + 'px'
    }, 30)
  }
}
function carouselChange(e) {}
function getApprInfo() {
  appraisalInfo().then((res) => {
    if (res.code == 200) {
      accumulate.value = res.data.num
      cost.value = Number(res.data.appraisal_fee) / 100
      appraisalList.value = res.data.list
    }
  })
}
function gopage(e) {
  router.push({ path: '/allGame', query: { type: e, cost: cost.value } })
}
const shopDetails = (i) => {
  router.push({ path: '/goods', query: { id: i.id, type: 'buy' } })
}
onMounted(() => {
  setTimeout(() => {
    runMarquee()
  }, 1000)
  getApprInfo()
  getList()
})
</script>
<style lang="less" scoped>
.ant-modal-content {
  border-radius: 20px;
  .ant-modal-body {
    padding: 0;
  }
}
.page-appraosal {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 20px;
  .breadcrumb {
    margin: 14px 0;
    span:nth-child(1) {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
    }
    img {
      margin: 0 3px;
    }
    span:nth-child(2) {
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
  }
  .header {
    width: 1200px;
    height: 441px;
    background: url('~@/assets/images/appraisal-bg.png') no-repeat;
    position: relative;
    .combination-bg {
      width: 217px;
      height: 23px;
      background: url('~@/assets/images/combination.png') no-repeat;
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      padding-left: 10px;
      padding-top: 3px;
      position: absolute;
      left: 650px;
    }
    .right {
      width: 550px;
      float: right;
      .title {
        text-align: center;
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        margin-top: 46px;
      }
      .accordion {
        width: 430px;
        margin: 0 auto;
        display: flex;
        margin-top: 24px;
        .accordion-left {
          width: 50%;
          p:nth-child(1) {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #7f8b99;
          }
          p:nth-child(2) {
            font-size: 32px;
            font-family: DIN-Bold, DIN;
            font-weight: bold;
            color: #353a40;
            margin-top: 20px;
          }
        }
        .accordion-right {
          width: 50%;
          p:nth-child(1) {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #7f8b99;
            margin-left: 26px;
          }
        }
        .marquee-wrap {
          position: relative;
          display: flex;
          overflow: hidden;
          width: 100%;
          height: 100%;
          margin-left: 20px;
        }
        .marquee-box {
          position: absolute;
          top: 50%;
          display: flex;
          white-space: nowrap;
          transform: translateY(-50%);
        }
        .marquee-list {
          margin-right: 10px;
          width: 50px;
          height: 50px;
          border-radius: 10px;
          img {
            width: 50px;
            height: 50px;
            border-radius: 10px;
          }
        }
        .marquee-list span {
          padding: 0 0.04rem;
          color: #ffe17b;
          font-weight: 700;
        }
      }
      .btn {
        width: 258px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        background: linear-gradient(270deg, #058aff 0%, #0dbaff 100%);
        border-radius: 20px;
        margin: 0 auto;
        margin-top: 34px;
        cursor: pointer;
      }
      .box {
        display: flex;
        justify-content: space-between;
        margin: 0 112px;
        margin-top: 20px;
        .summary {
          text {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7f8b99;
            margin-left: 2px;
          }
        }
      }
      .line {
        width: 100%;
        height: 1px;
        border: 1px solid #f2f2f2;
        margin: 30px 0;
      }
      .footer {
        display: flex;
        justify-content: space-between;
        padding: 0 26px;

        .footer-left {
          display: flex;
          img {
            margin-right: 15px;
          }
          p:nth-child(1) {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #323131;
          }
        }
        p:nth-child(2) {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #545c66;
        }
        .footer-right {
          line-height: 44px;
          cursor: pointer;
          text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }
  }
  .swiper {
    width: 1200px;
    border-radius: 10px;
    margin-top: 20px;
    .el-carousel {
      position: relative;
      /deep/ .el-carousel__indicators--outside {
        position: absolute !important;
        text-align: right;
        right: 0;
        bottom: 5px;
        padding-right: 15px;
        .el-carousel__button {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #ffffff;
          opacity: 1;
        }
      }
      /deep/ .el-carousel__indicator--horizontal.is-active button {
        background: linear-gradient(270deg, #ffa944 0%, #fe7600 100%);
      }
    }
    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
      border-radius: 20px;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
      border-radius: 20px;
    }
  }
  .best-box-title {
    margin: 20px 0;
    .left {
      display: flex;
      align-items: center;
      img {
        height: 24px;
      }
      p {
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-left: 8px;
      }
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .item {
      width: 288px;
      height: 292px;
      background: #ffffff;
      border-radius: 12px;
      //margin-left: 5px;
      margin-top: 16px;
      margin-right: 10px;
      .img {
        position: relative;
        img {
          width: 288px;
          height: 140px;
          border-radius: 12px 12px 0 0;
        }
        .official {
          width: 59px;
          height: 19px;
          font-size: 12px;
          text-align: center;
          font-family: YouSheBiaoTiHei;
          color: #ffffff;
          position: absolute;
          background: url(../../assets/images/authority-bg.png) no-repeat;
          top: 8px;
          left: 8px;
        }
        .user {
          width: 59px;
          height: 19px;
          font-size: 12px;
          text-align: center;
          font-family: YouSheBiaoTiHei;
          color: #ffffff;
          position: absolute;
          background: url(../../assets/images/individual-bg.png) no-repeat;
          top: 8px;
          left: 8px;
        }
      }
      .introduce {
        width: 100%;
        padding: 6px 12px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
      .price {
        display: flex;
        justify-content: space-between;
        padding: 8px 12px;
        border-bottom: 1px solid #f2f2f2;
        P:nth-child(1) {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
        P:nth-child(2) {
          font-size: 20px;
          font-family: DIN-Bold, DIN;
          font-weight: bold;
          color: #ff401b;
        }
      }
      .district {
        margin-top: 12px;
        margin-left: 12px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
    .item:nth-of-type(4n + 0) {
      margin-right: 0;
    }
  }
}
</style>
